import {
    Directive,
    ElementRef,
    EventEmitter,
    forwardRef,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import flatpickr from 'flatpickr';
import { Instance } from 'flatpickr/dist/types/instance';
import { BaseOptions } from 'flatpickr/dist/types/options';

@Directive({
    selector: '[appFlatpickr]',
    exportAs: 'appFlatpickr',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => FlatPickrDirective),
        },
    ],
})
export class FlatPickrDirective
    implements OnInit, OnChanges, ControlValueAccessor, OnDestroy
{
    @Input() public config: Partial<BaseOptions> = {};
    @Output() public flatPickrChange = new EventEmitter<Date | Date[]>();
    private onChange: (date: Date | Date[]) => void;
    private onTouched: () => void;
    private instance: Instance;

    public constructor(private elementRef: ElementRef) {}

    public ngOnInit(): void {
        // noinspection JSUnusedGlobalSymbols
        const config: Partial<BaseOptions> = {
            onChange: selectedDates => {
                let value: Date | Date[];

                if (config.mode === 'single') value = selectedDates[0];
                else if (config.mode === 'range' && selectedDates.length === 2)
                    value = selectedDates;

                if (value) this.reportChanged(value);
                else this.jumpToEnableDate();
            },
            ...{
                mode: 'single',
            },
            ...this.config,
        };

        this.instance = flatpickr(this.elementRef.nativeElement, config);
    }

    public ngOnChanges(): void {
        this.instance?.set(this.config);

        this.jumpToEnableDate();
    }

    public ngOnDestroy(): void {
        this.instance?.destroy();
    }

    public clear(): void {
        this.instance.clear();
        this.reportChanged(undefined);
    }

    public writeValue(obj: any): void {
        if (!this.instance) {
            return;
        }
        this.instance.setDate(obj);
    }

    public setDisabledState(isDisabled: boolean): void {
        this.instance.input.disabled = isDisabled;
    }

    public registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    public registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    private reportChanged(value: Date | Date[]): void {
        if (this.onTouched) {
            this.onTouched();
        }
        if (this.onChange) {
            this.onChange(value);
        }
        this.flatPickrChange.emit(value);
    }

    private jumpToEnableDate(): void {
        if (this.instance?.selectedDates?.length) return;

        const defaultFromDate = this.config.enable?.[0]?.['from'];

        if (!defaultFromDate) return;

        this.instance?.jumpToDate(defaultFromDate);
    }
}
