import {
    AfterViewInit,
    Component,
    ElementRef,
    Inject,
    Input,
    OnChanges,
    OnDestroy,
    ViewChild,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as Chart from 'chart.js';
import { forkJoin, Subject } from 'rxjs';
import { functions } from '@masar/common/misc/functions';
import { Kpi, KpiResult } from '@masar/common/models';
import { DOCUMENT } from '@angular/common';
import { getDistinctItems } from '@masar/common/utils';
import ChartDataLabels from 'chartjs-plugin-datalabels';
@Component({
    selector: 'app-kpi-result-target-chart',
    templateUrl: './kpi-result-target-chart.component.html',
    styles: [
        `
            :host {
                display: block;
                width: 100%;
                height: 100%;
            }
        `,
    ],
})
export class KpiResultTargetChartComponent
    implements AfterViewInit, OnChanges, OnDestroy
{
    @Input() public kpi: Kpi;

    // Checks if the result is owned by the department
    // before displaying the result on the graph. Should
    // be set to false if the kpi has only a single result
    // per year that represents the overall result of
    // the kpi.
    @Input() public checkIsOwning: boolean = true;

    @ViewChild('canvasRef')
    private canvasRef: ElementRef<HTMLCanvasElement>;

    public hideShowResultStatus: boolean = false;

    public years: number[] = [];

    public selectedYears: number[] = [];

    private chart: Chart;
    private sortedResults: KpiResult[];
    private unsubscribeAll = new Subject();

    public constructor(
        private translateService: TranslateService,
        @Inject(DOCUMENT) private document: Document
    ) {}

    public ngAfterViewInit(): void {
        // noinspection JSUnusedGlobalSymbols
        this.chart = new Chart(this.canvasRef.nativeElement.getContext('2d'), {
            type: 'bar',
            plugins: [ChartDataLabels], // Register plugin for this chart
            options: {
                scales: {
                    yAxes: [
                        {
                            display: true,
                            ticks: {
                                suggestedMin: 0,
                            },
                        },
                    ],
                },
                plugins: {
                    labels: { render: () => {} }, // eslint-disable-line @typescript-eslint/no-empty-function
                },
            },
        });
        this.redrawChart();
    }

    // When kpi value changes.
    public ngOnChanges(): void {
        this.updateYears();
        this.redrawChart();
    }

    public ngOnDestroy(): void {
        this.unsubscribeAll.next();
        this.unsubscribeAll.complete();
    }

    public redrawChart(): void {
        if (!this.kpi || !this.chart) {
            return;
        }

        const style = getComputedStyle(this.document.documentElement);
        const primaryColor = style.getPropertyValue('--primary-500');
        const secondaryColor = style.getPropertyValue('--secondary-500');

        let kpiResults = this.kpi.results
            .slice()
            .sort((x, y) => x.year - y.year)
            .filter(x => !this.checkIsOwning || x.isOwningDepartment);

        if (this.selectedYears.length > 0)
            kpiResults = kpiResults.filter(x =>
                this.selectedYears.includes(x.year)
            );

        this.sortedResults = kpiResults;

        forkJoin([
            this.translateService.get('translate_result'),
            this.translateService.get('translate_target'),
        ]).subscribe(([resultStr, targetStr]) => {
            const dataset: Chart.ChartDataSets[] = [];

            dataset.push({
                label: resultStr,
                data: this.sortedResults.map(x =>
                    functions.roundNumber(x.result, 2)
                ),
                backgroundColor: secondaryColor,
                borderColor: secondaryColor,
                order: 1,
                datalabels: {
                    display: () => this.hideShowResultStatus,
                    textAlign: 'center',
                    color: secondaryColor,
                    anchor: 'end',
                    align: 'top',
                    offset: -5,
                    formatter: value => functions.roundNumber(value, 2),
                    font: {
                        weight: 'bold',
                    },
                },
            });

            if (!this.kpi.isTrend) {
                dataset.push({
                    label: targetStr,
                    data: this.sortedResults.map(x =>
                        functions.roundNumber(x.target, 2)
                    ),
                    backgroundColor: primaryColor,
                    borderColor: primaryColor,
                    order: 0,
                    datalabels: {
                        display: () => this.hideShowResultStatus,
                        textAlign: 'center',
                        color: primaryColor,
                        anchor: 'end',
                        align: 'top',
                        offset: -5,
                        formatter: value => functions.roundNumber(value, 2),
                        font: {
                            weight: 'bold',
                        },
                    },
                });
            }

            this.chart.data.datasets = dataset;
            this.chart.data.labels = this.sortedResults.map(x => x.year);

            // this.chart.options.scales.yAxes[0].ticks.suggestedMax =
            //     Math.max(...this.sortedResults.map(x => x.achieved * 100)) + 10;
            this.chart.update();
        });
    }
    public onToggle(): void {
        this.hideShowResultStatus = !this.hideShowResultStatus;
        this.redrawChart();
    }

    private updateYears(): void {
        if (!this.kpi) return;
        const allYears = this.kpi.results.map(x => x.year);
        this.years = getDistinctItems(allYears).sort((x, y) => y - x);
    }
}
