import {
    AfterViewInit,
    Component,
    ElementRef,
    Inject,
    Input,
    OnChanges,
    OnDestroy,
    ViewChild,
} from '@angular/core';
import * as Chart from 'chart.js';
import { Subject } from 'rxjs';
import { functions } from '@masar/common/misc/functions';
import { Kpi, KpiResult } from '@masar/common/models';
import { DOCUMENT } from '@angular/common';
import 'chartjs-plugin-labels';
import { AppSettingFetcherService } from '@masar/core/services';
import { takeUntil } from 'rxjs/operators';
import { getDistinctItems } from '@masar/common/utils';
import ChartDataLabels from 'chartjs-plugin-datalabels';

@Component({
    selector: 'app-kpi-result-achieved-chart',
    templateUrl: './kpi-result-achieved-chart.component.html',
    styles: [
        `
            :host {
                display: block;
                width: 100%;
                height: 100%;
            }
        `,
    ],
})
export class KpiResultAchievedChartComponent
    implements AfterViewInit, OnChanges, OnDestroy
{
    @Input() public kpi: Kpi;

    // Checks if the result is owned by the department
    // before displaying the result on the graph. Should
    // be set to false if the kpi has only a single result
    // per year that represents the overall result of
    // the kpi.
    @Input() public checkIsOwning: boolean = true;

    @ViewChild('canvasRef')
    private canvasRef: ElementRef<HTMLCanvasElement>;

    public hideShowResultStatus: boolean = false;

    public years: number[] = [];

    public selectedYears: number[] = [];

    private chart: Chart;
    private sortedResults: KpiResult[];
    private unsubscribeAll = new Subject();

    public constructor(
        private appSettingFetcherService: AppSettingFetcherService,
        @Inject(DOCUMENT) private document: Document
    ) {}

    public ngAfterViewInit(): void {
        // noinspection JSUnusedGlobalSymbols
        this.chart = new Chart(this.canvasRef.nativeElement.getContext('2d'), {
            type: 'bar',
            plugins: [ChartDataLabels],
            options: {
                scales: {
                    yAxes: [
                        {
                            display: true,
                            ticks: {
                                suggestedMin: 0,
                            },
                        },
                    ],
                },
                legend: {
                    display: false,
                },
            },
        });
        this.redrawChart();

        this.appSettingFetcherService.get$
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe(settings => {
                this.toggleTicks(settings.kpiSetting.isGraphTicksEnabled);
            });
    }

    // When kpi value changes.
    public ngOnChanges(): void {
        this.updateYears();
        this.redrawChart();
    }

    public ngOnDestroy(): void {
        this.unsubscribeAll.next();
        this.unsubscribeAll.complete();
    }

    public redrawChart(): void {
        if (!this.kpi || !this.chart) {
            return;
        }

        const style = getComputedStyle(this.document.documentElement);
        const primaryColor = style.getPropertyValue('--primary-500');
        const secondaryColor = style.getPropertyValue('--secondary-500');

        let kpiResults = this.kpi.results
            .slice()
            .sort((x, y) => x.year - y.year)
            .filter(x => !this.checkIsOwning || x.isOwningDepartment);

        if (this.selectedYears.length > 0)
            kpiResults = kpiResults.filter(x =>
                this.selectedYears.includes(x.year)
            );

        this.sortedResults = kpiResults;

        const dataset: Chart.ChartDataSets[] = [];

        if (!this.kpi.isTrend) {
            dataset.push({
                data: this.sortedResults.map(x =>
                    functions.roundNumber(x.achieved * 100, 2)
                ),
                barThickness: 30,
                backgroundColor: secondaryColor,
                borderColor: secondaryColor,
                order: 1,
                datalabels: {
                    display: () => this.hideShowResultStatus,
                    textAlign: 'center',
                    color: primaryColor,
                    anchor: 'end',
                    align: 'top',
                    offset: 6,
                    formatter: value => functions.roundNumber(value, 2),
                    font: {
                        weight: 'bold',
                    },
                },
            });

            dataset.push({
                data: this.sortedResults.map(x =>
                    functions.roundNumber(x.achieved * 100, 2)
                ),
                type: 'line',
                fill: false,
                borderColor: primaryColor,
                backgroundColor: primaryColor,
                pointBorderWidth: 10,
                order: 0,
                datalabels: {
                    display: false,
                },
            } as any);
        }
        this.chart.data.datasets = dataset;
        this.chart.data.labels = this.sortedResults.map(x => x.year);

        this.chart.options.scales.yAxes[0].ticks.suggestedMax =
            Math.max(...this.sortedResults.map(x => x.achieved * 100)) + 20;

        this.chart.update();
    }
    public onToggle(): void {
        this.hideShowResultStatus = !this.hideShowResultStatus;
        this.redrawChart();
    }
    private updateYears(): void {
        if (!this.kpi) return;
        const allYears = this.kpi.results.map(x => x.year);
        this.years = getDistinctItems(allYears).sort((x, y) => y - x);
    }

    private toggleTicks(enable: boolean): void {
        const compute = (args): number => {
            const { index } = args;
            const { type } = args.dataset;
            const previousAchieved =
                this.sortedResults[index - 1]?.achieved ?? null;
            const currentAchieved = this.sortedResults[index]?.achieved ?? null;

            if (
                type !== 'line' ||
                index === 0 ||
                previousAchieved === null ||
                currentAchieved === null ||
                (this.kpi?.isTrend ?? true)
            ) {
                return null;
            }

            return currentAchieved - previousAchieved;
        };

        const ticksPlugin = {
            render: args => {
                const change = compute(args);

                const symbol = change < 0 ? '▼' : change > 0 ? '▲' : '';

                return change === null || !enable
                    ? ''
                    : `${functions.roundNumber(change * 100, 1)}%${symbol} `;
            },

            fontColor: args => {
                const change = compute(args);
                return change > 0
                    ? '#075c07'
                    : change < 0
                    ? '#a22e2e'
                    : '#000000';
            },
            fontStyle: 'bold',
            position: 'border',
            textMargin: 10,
        };

        this.chart.options.plugins = {
            labels: ticksPlugin,
        };
        this.chart.update();
    }
}
