<div class="mb-4 rounded-md bg-gray-100 px-4 py-4">
    <!-- Show hide result -->
    <div class="flex-grow">
        <div class="grid grid-cols-1 gap-4 pb-3 text-center md:grid-cols-1">
            <div>
                <input
                    type="checkbox"
                    id="hide-show-result-achieved"
                    (change)="onToggle()"
                />
                <label for="hide-show-result-achieved">
                    {{ 'translate_hide_show_results' | translate }}
                </label>
            </div>
        </div>
    </div>
    <!-- Years -->
    <div class="flex-grow">
        <label class="mb-2 block font-bold text-gray-700" for="years">
            {{ 'translate_years' | translate }}
        </label>
        <ng-select
            id="years"
            [items]="years"
            [multiple]="true"
            placeholder="{{ 'translate_years' | translate }}"
            [(ngModel)]="selectedYears"
            (change)="redrawChart()"
        >
        </ng-select>
    </div>
</div>

<h5 class="text-center font-bold">
    {{ 'translate_kpi_improvement' | translate }}
</h5>

<canvas #canvasRef></canvas>
